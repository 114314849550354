import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from '../reducer/Reducer'

const AppContext = createContext()
let API = `http://www.omdbapi.com/?apikey=727bbdc1`
const initialState={
    isLoading: false,
    isError: {
        show: "false",
        msg: ""
    },
    movies: [],
    query: "titanic",
    isSingleLoading: false,
    singleProduct: {},
}

const AppProvider=({children})=>{

    const [state, dispatch] = useReducer(reducer, initialState)

    const getMovies=async(url)=>{
        dispatch({type: "SET_LOADING"})
        try{
            const res = await fetch(url)
            const data = await res.json()
            console.log(data)
            if(data.Response === "True"){
                dispatch({type: "GET_MOVIES", payload: data.Search})
            }else{
                dispatch({type: "GET_ERROR_MSG", payload: data.Error})
            }
            
        }catch(err){
            console.log(err)
            dispatch({type: "SET_ERROR", payload: err})
        }
    }

    const getSingleProduct = async (url) => {
        dispatch({ type: "SET_SINGLE_LOADING" });
        try {
            const res = await fetch(url)
            const data = await res.json()
            console.log(data)
            if(data.Response === "True"){
                dispatch({ type: "SET_SINGLE_PRODUCT", payload: data });
            }else{
                dispatch({type: "GET_ERROR_MSG", payload: data.Error})
            }
         
        } catch (error) {
          dispatch({ type: "SET_SINGLE_ERROR", payload: error});
        }
      };

   const setQuery = (searchItem) =>{
    dispatch({type: "SEARCH", payload: searchItem})
   }

     useEffect(()=>{
        getMovies(`${API}&s=${state.query}`);
     },[state.query])
    return(
     <AppContext.Provider value={{...state, getSingleProduct, setQuery}}>
        {children}
     </AppContext.Provider>

    )
}

const useGlobalContext =(apiParams)=>{
    return useContext(AppContext)
}

export { AppContext, AppProvider, useGlobalContext}