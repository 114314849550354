
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home'
import  SingleMovie from './SingleMovie'
import ErrorPage from './ErrorPage'

function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>
    <Route path='/' element={<Home />} ></Route>
     <Route path='movie/:id' element={<SingleMovie />} ></Route> 
    <Route path='*' element={<ErrorPage />} ></Route>
   </Routes>
    
    </BrowserRouter>
    </>
  );
}

export default App;
