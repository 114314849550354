const Reducer = (state, action)=>{
    if(action.type === "SET_LOADING"){
        return{
            ...state,
            isLoading: true
        }
    }

    if(action.type === "GET_MOVIES"){
        return{
            ...state,
            isLoading: false,
            movies: action.payload,
            isError: {
              ...state.isError,
              show: "false",
              msg: ""
            }
        }
    }

    if(action.type === "SET_ERROR"){
        return{
            ...state,
            isLoading: false,
            isError: {
              ...state.isError,
              show: "true",
              msg: action.payload
            }
        }
    }

  
    if(action.type === "SEARCH"){
        return {
          ...state,
          query:action.payload,
        };
    }
    if(action.type === "SET_SINGLE_LOADING"){
      return {
        ...state,
        isSingleLoading: true,
      };
    }
    if(action.type === "SET_SINGLE_PRODUCT"){
      return {
        ...state,
        isSingleLoading: false,
        singleProduct: action.payload,
        isError: {
          ...state.isError,
          show: "false",
          msg: ""
        }
      };
    }
    if(action.type === "SET_SINGLE_ERROR"){
      return {
        ...state,
        isSingleLoading: false,
        isError: {
          ...state.isError,
          show: "true",
          msg: action.payload
        }
      };
    }
      if(action.type === "GET_ERROR_MSG"){
        return {
          ...state,
          isSingleLoading: false,
          isError: {
            ...state.isError,
            show: "true",
            msg: action.payload
          }
        };
    }
}

export default Reducer