import React from 'react'
import Movie from './components/Movie'
import Search from './components/Search'

const Home = () => {
  return (
    <>
    <Search />
    <Movie />
    
    </>
  )
}

export default Home