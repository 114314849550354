import React, { useEffect } from 'react'
import { useGlobalContext } from './context/context';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
let API = `http://www.omdbapi.com/?apikey=727bbdc1`
const SingleMovie = () => {
    const { getSingleProduct, isSingleLoading, singleProduct } = useGlobalContext();

     const { id } = useParams();
     console.log(id)

     const { Poster, Title, imdbRating, Country, Released, Genre } = singleProduct
     useEffect(()=>{
        getSingleProduct(`${API}&i=${id}`);
     },[])
    
    
    if (isSingleLoading) {
        return (
          <section className="movie-section ">
            <div className="loading">Loading....</div>;
          </section>
        );
      }
    
      return (
        <section className="movie-section">
            
          <div className="movie-card">
            <figure>
              <img src={Poster} alt="" />
            </figure>
            <div className="card-content">
              <p className="title">{Title}</p>
              <p className=""></p>
              <p className="card-text">{Released}</p>
              <p className="card-text">{Genre}</p>
              <p className="card-text">{imdbRating} / 10</p>
              <p className="card-text">{Country}</p>
              <NavLink to="/" className="back-btn">
                Go Back
              </NavLink>
            </div>
          </div>
        </section>
      );
}

export default SingleMovie